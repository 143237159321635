<template>
  <div>
    <div class="alert alert-primary" role="alert">
      Pricing based on
      <strong>Billing Account</strong> and
      <strong>Account</strong>
    </div>
    <b-row>
      <b-col xl="6" lg="6" md="12" sm="12">
        <!--  -->
        <div v-if="billing_account_">
          <EditableCard
            :title="'Billing Account - ' + billing_account_['id']"
            :params.sync="billing_account_"
            :relationship="$d.getRelationship('BillingAccount', 'HasProductPricing')"
            @submitted="getFinalPricing()"
          />
        </div>
        <div v-else>
          <div class="alert alert-warning">Billing Account Required for Pricing based on Billing Accounts</div>
        </div>
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12">
        <!--  -->
        <div v-if="account_">
          <EditableCard
            :title="'Account - ' + account_['account_number']"
            :params.sync="account_"
            :relationship="$d.getRelationship('Account', 'HasProductPricing')"
            @submitted="getFinalPricing()"
          />
        </div>
        <div v-else>
          <div class="alert alert-warning">Account Required for Pricing based on Accounts</div>
        </div>
      </b-col>
    </b-row>
    <hr />
    <!-- TODO: This is Considered an Unsafe update, does not go through Account's validator of the Addresses -->
    <template v-for="(addr, index) in addresses_">
      <div :key="addr['id']">
        <div class="alert alert-primary" role="alert">
          Pricing based on
          <strong>{{ 'Address - ' + addressInfo(addr) }}</strong>
        </div>
        <!-- {{addresses_[index]}} -->
        <b-row>
          <b-col xl="6" lg="6" md="12" sm="12">
            <EditableCard
              :title="'Address - ' + addressInfo(addr)"
              :params.sync="addresses_[index]"
              :relationship="$d.getRelationship('Address', 'HasProductPricing')"
              @submitted="getFinalPricing()"
              :with_relationships="[
                {
                  from_class_name: 'Address',
                  relationship_name: 'HasFinalProductPricing',
                },
              ]"
            />
          </b-col>
          <b-col xl="6" lg="6" md="12" sm="12">
            <EditableCard
              ref="finalPricingCardRef"
              :title="'Final - Address - ' + addressInfo(addr)"
              :params.sync="addresses_[index]"
              :relationship="$d.getRelationship('Address', 'HasFinalProductPricing')"
            />
          </b-col>
        </b-row>
        <hr />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  components: {
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {
    account: {
      required: true,
    },
    billing_account: {
      required: true,
    },
    addresses: {
      required: true,
    },
  },
  data: () => {
    return {
      // addresses_: []
    };
  },
  computed: {
    account_: {
      get: function() {
        return this.account;
      },
      set: function(newValue) {
        this.$emit('update:account', newValue);
      },
    },
    billing_account_: {
      get: function() {
        return this.billing_account;
      },
      set: function(newValue) {
        this.$emit('update:billing_account', newValue);
      },
    },
    addresses_: {
      get: function() {
        return this.addresses;
      },
      set: function(newValue) {
        this.$emit('update:addresses', newValue);
      },
    },
  },
  watch: {
    /* addresses: {
      immediate: true,
      handler(to, from) {
        this.addresses_ = _.cloneDeep(to);
      }
    } */
  },
  created() {},
  methods: {
    getFinalPricing() {
      this.$refs['finalPricingCardRef'].forEach(finalPricingCardRef => {
        finalPricingCardRef.get();
      });
    },
    addressInfo(address) {
      if (address['name']) {
        return address['name'];
      }
      return 'ID: ' + address['id'];
    },
  },
};
</script>
